import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Data from "../../api/data.json";
import { GlobalDataContext } from "../../context/context";

const BannerV2 = (props) => {
  const { rpdata } = useContext(GlobalDataContext);
  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <div
      className="banner-area banner-area-2"
      style={{ backgroundImage: `url("${rpdata?.stock?.[2]})"`, paddingTop: "320px" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-8 align-self-center">
            <div className="banner-inner style-white text-center text-lg-left">
              <h6 className="b-animate-1 sub-title">
                {rpdata?.dbPrincipal?.name}
              </h6>
              <h1 className="b-animate-2 title">
                {rpdata?.dbSlogan?.[1].slogan}
              </h1>
              <Link
                className="btn btn-base b-animate-3 mr-sm-3 mr-2"
                to="/contact"
              >
                Free Estimate
              </Link>
              <Link className="btn btn-border-white b-animate-3" to="/gallery">
                Projects
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerV2;
